<template>
  <span>
    <page-header
      title="Liste des lieux" icon="fas fa-list"
      :links="pageLinks"
    ></page-header>
    <div>
      <b-row>
        <b-col cols="4">
          <div :ref="activitiesPlace === null ? 'pdfAndXls' : ''">
            <table class="table table-striped">
              <tr v-if="places.length === 0">
                <td><div class="empty-text">Aucun lieu</div></td>
              </tr>
              <tr
                v-for="place in places"
                :key="place.id"
                class="row-line"
                :class="{ selected: activitiesPlace === place}"
              >
                <td>
                  <div :style="getPlaceStyle(place)">
                    {{ place.name }}
                    <span class="always-available" v-if="place.alwaysAvailable">
                      <i class="fa fa-warning"></i> Toujours disponible
                    </span>
                  </div>
                </td>
                <td v-if="canViewActivities && !printing" class="text-right no-print">
                  <a href
                   @click.prevent="showActivities(place)"
                   class="btn btn-secondary btn-xs"
                  >
                    <i class="fas fa-chess-knight"></i> Activités
                  </a>
                </td>
                <td v-if="canChange && !printing" class="text-right no-print">
                  <a href
                   @click.prevent="showModal(place)"
                   class="btn btn-secondary btn-xs"
                  >
                    <i class="fas fa-pen"></i> Modifier
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </b-col>
        <b-col cols="8">
          <div v-if="activitiesPlace" ref="pdfAndXls">
            <table class="table table-striped table-responsive">
              <tr>
                <th colspan="5">{{ activitiesPlace.name }}</th>
                <th v-if="activities.length">Séances</th>
                <th v-if="activities.length">Dates</th>
                <th v-if="activities.length">Inscrits</th>
                <th v-if="activities.length">Max</th>
              </tr>
              <tr v-if="activities.length === 0">
                <td><div class="empty-text">Aucune activité</div></td>
              </tr>
              <tr
                v-for="activity in activities"
                :key="activity.id"
                class="row-line"
              >
                <td>{{ activity.name }}</td>
                <td>{{ activity.schoolYear.name }}</td>
                <td>
                  <span v-if="activity.day">
                    {{ activity.day }}
                  </span>
                  <span v-else>
                    &nbsp;
                  </span>
                </td>
                <td>
                  <span v-if="activity.startTime">
                    {{ hour(activity.startTime) }}
                  </span>
                  <span v-else>
                    &nbsp;
                  </span>
                </td>
                <td>
                  <span v-if="activity.endTime">
                    {{ hour(activity.endTime) }}
                  </span>
                  <span v-else-if="activity.duration">
                    Durée: {{ duration(activity.duration) }}
                  </span>
                  <span v-else>
                    &nbsp;
                  </span>
                </td>
                <td class="number">{{ activity.seancesCount }}</td>
                <td class="number">
                  <router-link :to="activityDatesLink(activity)">
                    {{ activity.datesCount }}
                  </router-link>
                </td>
                <td class="number">
                  <router-link :to="activityLink(activity)">
                    {{ activity.inscriptionsCount }}
                  </router-link>
                </td>
                <td class="number">{{ activity.maximumParticipants }}</td>
              </tr>
            </table>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal
      dialog-class="modal-md"
      id="bv-modal-edit-place"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Enregistrer"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fas fa-pen"></i>&nbsp;
          <span v-if="selectedPlace">Modifier {{ selectedPlace.name }}</span>
          <span v-else>Ajouter un lieu</span>
        </b>
      </template>
      <div v-if="errorText" class="error-text">{{ errorText }}</div>
      <b-row>
        <b-col>
          <b-form-input
            v-model="newName"
            required
            placeholder="Entrez le nom du lieu"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <color-select
            :background="background"
            :color="color"
            @selected="getSelectedColor($event)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            description="Si décoché, la disponibilité du lieux n'est pas vérifiée lors de l'ajout d'une date"
          >
            <b-form-checkbox v-model="alwaysAvailable">
              Toujours disponible
            </b-form-checkbox>
          </b-form-group>
          <div v-if="alwaysAvailable" class="warning-text">
            <i class="fa fa-warning"></i> Attention. Il sera possible de créer plusieurs
            évènements simultanés pour ce lieu.
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import PageHeader from '@/components/Layout/PageHeader.vue'
import { BackendApi, openDocument } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { makePlace } from '@/types/base'
import { makeActivity } from '@/types/activities'
import { compareNumbers, compareStrings } from '@/utils/sorting'
import { compareTimes } from '@/utils/time'
import { duration, hour } from '@/filters/texts'
import { slugify } from '@/utils/strings'
import ColorSelect from '@/components/Controls/ColorSelect.vue'

export default {
  name: 'place-list',
  mixins: [BackendMixin],
  components: {
    ColorSelect,
    PageHeader,
  },
  data() {
    return {
      places: [],
      selectedPlace: null,
      activitiesPlace: null,
      errorText: '',
      newName: '',
      activities: [],
      printing: false,
      background: '',
      color: '',
      alwaysAvailable: false,
    }
  },
  async mounted() {
    await this.loadPlaces()
    const place = +(this.$route.query ? this.$route.query.place : 0)
    if (place) {
      const places = this.places.filter(elt => elt.id === place)
      if (places.length) {
        await this.showActivities(places[0])
      }
    }
  },
  computed: {
    canAdd() {
      return this.hasPerm('home.add_place')
    },
    canChange() {
      return this.hasPerm('home.change_place')
    },
    canViewActivities() {
      return this.hasOnePerm(
        ['activities.view_coreactivity', 'youth.view_activity']
      )
    },
    pageLinks() {
      const cssClass = 'btn-secondary'
      const links = [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: cssClass,
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: cssClass,
        }
      ]
      if (this.canAdd) {
        links.push(
          {
            id: 3,
            label: 'Ajouter',
            callback: this.addOne,
            icon: 'fa fa-plus',
            cssClass: 'btn-primary',
          }
        )
      }
      return links
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    hour(val) {
      return hour(val)
    },
    duration(value) {
      return duration(value)
    },
    showModal(place) {
      this.errorText = ''
      this.selectedPlace = place
      this.newName = place.name
      this.background = place.backgroundColor
      this.color = place.textColor
      this.alwaysAvailable = place.alwaysAvailable
      this.$bvModal.show('bv-modal-edit-place')
    },
    dayIndex(activity) {
      const day = activity.day.toLowerCase()
      const dayNames = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']
      for (let index = 0; index < dayNames.length; index++) {
        if (day.indexOf(dayNames[index]) >= 0) {
          return index
        }
      }
      return -1
    },
    compareActivities(act1, act2) {
      let value = compareNumbers(act1.schoolYear.startYear, act2.schoolYear.startYear)
      if (value === 0) {
        const dayIndex1 = this.dayIndex(act1)
        const dayIndex2 = this.dayIndex(act2)
        value = compareNumbers(dayIndex1, dayIndex2)
        if (value === 0) {
          value = compareTimes(act2.startTime, act1.startTime)
          if (value === 0) {
            value = compareTimes(act2.endTime, act1.endTime)
            if (value === 0) {
              value = compareStrings(act1.name, act2.name)
            }
          }
        }
      }
      return value
    },
    async showActivities(place) {
      if (this.activitiesPlace === place) {
        this.activitiesPlace = null
        this.activities = []
      } else {
        this.activitiesPlace = place
        let url = '/tools/api/all-activities/?place=' + place.id
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.activities = resp.data.map(elt => makeActivity(elt)).sort(
            this.compareActivities
          )
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
      let queryArgs = { ...this.$route.query, }
      queryArgs.place = this.activitiesPlace ? this.activitiesPlace.id : 0
      await this.$router.replace({ query: queryArgs, })
    },
    addOne() {
      this.errorText = ''
      this.selectedPlace = null
      this.newName = ''
      this.$bvModal.show('bv-modal-edit-place')
    },
    async loadPlaces() {
      let url = '/api/home/places/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.places = resp.data.map(elt => makePlace(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async onSave() {
      const data = {
        name: this.newName,
        background_color: this.background,
        text_color: this.color,
        always_available: this.alwaysAvailable,
      }
      let backendApi
      if (this.selectedPlace) {
        const url = '/api/home/places/' + this.selectedPlace.id + '/'
        backendApi = new BackendApi('patch', url)
      } else {
        const url = '/api/home/places/'
        backendApi = new BackendApi('post', url)
      }
      try {
        let resp = await backendApi.callApi(data)
        await this.addSuccess('Le lieu a été enregistré')
        this.$bvModal.hide('bv-modal-edit-place')
        await this.loadPlaces()
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    async excelMe() {
      this.printing = true
      const that = this
      this.$nextTick(
        async function() {
          const docUrl = '/documents/table-to-excel/<key>/'
          const docSlug = that.activitiesPlace ? slugify(that.activitiesPlace.name) : 'lieux'
          const docContent = that.$refs.pdfAndXls.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await this.addError(that.getErrorText(err))
          }
          that.printing = false
        }
      )
    },
    getSelectedColor(event) {
      this.background = event.color.background
      this.color = event.color.text
    },
    async printMe() {
      this.printing = true
      const that = this
      this.$nextTick(
        async function() {
          let docUrl = '/documents/standard/<key>/pdf/'
          const docSlug = that.activitiesPlace ? slugify(that.activitiesPlace.name) : 'lieux'
          const docContent = that.$refs.pdfAndXls.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await this.addError(that.getErrorText(err))
          }
          that.printing = false
        }
      )
    },
    activityLink(activity) {
      let dest
      if (activity.youth) {
        dest = { name: 'youth-homes-detail', params: { activityId: '' + activity.id, }, }
      } else {
        dest = { name: 'activities-detail', params: { activityId: '' + activity.id, }, }
      }
      return dest
    },
    activityDatesLink(activity) {
      return {
        name: 'activity-dates',
        params: { activityId: '' + activity.id, youthHome: activity.youth ? '1' : '0', }, }
    },
    getPlaceStyle(place) {
      return {
        background: place.backgroundColor,
        color: place.textColor,
        padding: '2px',
      }
    },
  },
}
</script>

<style scoped>
tr.selected td,
tr:nth-of-type(odd).selected td,
tr:nth-of-type(even).selected td {
  background: #444;
  color: #fff;
}
.number {
  text-align: center;
}
.always-available {
  display: inline-block;
  background: #f8a500;
  padding: 2px 5px;
  color: #000;
  border-radius: 4px;
  font-size: 11px;
  vertical-align: middle;
}
</style>
