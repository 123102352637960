
const colors = [
  { name: 'Par défaut', background: '', text: '', },
  { name: 'Rouge clair', background: '#f85656', text: '#000', },
  { name: 'Rouge médium', background: '#d00b4c', text: '#fff', },
  { name: 'Rouge foncé', background: '#950334', text: '#fff', },
  { name: 'Brun', background: '#7b193a', text: '#fff', },
  { name: 'Orange clair', background: '#f8ce8d', text: '#000', },
  { name: 'Or', background: '#ffd700', text: '#000', },
  { name: 'Orange', background: '#f89301', text: '#000', },
  { name: 'Jaune très clair', background: '#ffffe0', text: '#000', },
  { name: 'Jaune clair', background: '#fbff8d', text: '#000', },
  { name: 'Jaune sombre', background: '#b8b81f', text: '#fff', },
  { name: 'Vert clair', background: '#90ff84', text: '#000', },
  { name: 'Vert medium', background: '#10c962', text: '#fff', },
  { name: 'Vert foncé', background: '#0b7c3b', text: '#fff', },
  { name: 'Vert sombre', background: '#0a542b', text: '#fff', },
  { name: 'Olive', background: '#808000', text: '#fff', },
  { name: 'Bleu clair', background: '#8df6ff', text: '#000', },
  { name: 'Cyan', background: '#1fd3d3', text: '#000', },
  { name: 'Bleu medium1', background: '#65bafc', text: '#000', },
  { name: 'Bleu medium2', background: '#3d869b', text: '#fff', },
  { name: 'Bleu foncé', background: '#0b2e79', text: '#fff', },
  { name: 'Rose très clair', background: '#f8d8f2', text: '#000', },
  { name: 'Rose clair', background: '#ffa3f4', text: '#000', },
  { name: 'Rose foncé', background: '#e432ff', text: '#fff', },
  { name: 'Violet', background: '#af47ea', text: '#fff', },
  { name: 'Violet foncé', background: '#7200ae', text: '#fff', },
  { name: 'Magenta foncé', background: '#8b008b', text: '#fff', },
  { name: 'Gris clair', background: '#ccc', text: '#000', },
  { name: 'Gris medium', background: '#888', text: '#000', },
  { name: 'Gris foncé', background: '#666', text: '#fff', },
  { name: 'Gris sombre', background: '#444', text: '#fff', },
  { name: 'Blanc', background: '#fff', text: '#000', },
  { name: 'Noir', background: '#222', text: '#fff', }
]

export function getColors(): any[] {
  return colors
}

export function getColor(index: number, ignoreWhite: boolean = false): any {
  let selectedColors = colors
  if (ignoreWhite) {
    selectedColors = selectedColors.filter(
      elt => (elt.background !== '') && (elt.background !== '#fff')
    )
  }
  let colorsCount = selectedColors.length
  let colorIndex = index % colorsCount
  return selectedColors[colorIndex]
}
