<template>
  <span class="color-select">
      <b-row>
        <b-col>
          <b-form-group
            id="colors-group"
            label="Couleur"
            label-for="colors"
          >
            <b-input-group>
              <b-form-select  v-model="selectedColor" id="colors">
                <b-form-select-option
                  :value="color"
                  v-for="color in colors"
                  :key="color.id"
                  >
                  {{color.name}}
                </b-form-select-option>
              </b-form-select>
              <b-input-group-append :style="getColorStyle()" class="display-color">
                <b>A</b>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
  </span>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import { getColors } from '@/utils/colors'

export default {
  name: 'ColorSelect',
  mixins: [BackendMixin],
  props: {
    background: String,
    color: String,
  },
  data() {
    return {
      name: '',
      selectedColor: {},
    }
  },
  computed: {
    colors() {
      const colors = getColors()
      return colors.map(
        (elt, index) => {
          elt.id = index + 1
          return elt
        }
      )
    },
  },
  mounted() {
    this.init()
  },
  watch: {
    selectedColor: function() {
      this.$emit('selected', { color: this.selectedColor, })
    },
    background: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      if (this.background) {
        const colors = this.colors.filter(color => color.background === this.background && color.text === this.color)
        if (colors.length) {
          this.selectedColor = colors[0]
        } else {
          this.selectedColor = this.colors[0]
        }
      } else {
        this.selectedColor = this.colors[0]
      }
    },
    getColorStyle() {
      return {
        backgroundColor: this.selectedColor.background || '#ececec',
        color: this.selectedColor.text || '#454851',
      }
    },
  },
}
</script>

<style scoped>
.display-color {
  width: 38px;
  padding-top: 6px;
  justify-content: center;
  border-radius: 0 4px 4px 0;
  border: solid 1px #ccc;
}
</style>
